const SEO = [
	{
		page: "home",
		description:
			"Hi, I'm Marcos, a KDB+ programmer based in Toronto. My current role primarily involves in-depth research and analysis of data from Canadian marketplaces. I mainly work on collaborative research concerning market microstructures and sector trends. With a background in pure mathematics, I am passionate about applying pure maths, statistics, and linear algebra to optimize algorithms in space-time. I also leverage mathematical theories to create trading strategies and to understand the market.",
		keywords: ["Marcos", "Marcos Jaen", "KDB", "KDB+", "Quant"],
	},

	{
		page: "about",
		description:
			"I am a KDB+ programmer based in Toronto. I have experience research and developmement experience with tick data from Canadian marketplaces.",
		keywords: ["Marcos", "Marcos Jaen", "KDB", "KDB+", "Quant"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects involving researching trading strategies, building robust pipelines and applications. Many of these projects are open-source and available here for others to explore.",
		keywords: ["Marcos", "Marcos Jaen", "KDB", "KDB+", "Quant"],
	},

	{
		page: "articles",
		description:
			"These are some of the ideas I have in mind and will love to research/develop.",
		keywords: ["Marcos", "Marcos Jaen", "KDB", "KDB+", "Quant"],
	},

	{
		page: "contact",
		description:
			"I'm always open to chat. Feel free to reach out to me for anything at jaenmarcos15@gmail.com.",
		keywords: ["Marcos", "Marcos Jaen", "KDB", "KDB+", "Quant"],
	},
];

export default SEO;
