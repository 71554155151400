const INFO = {
	main: {
		title: "Marcos Jaen - Home",
		name: "Marcos Jaen",
		email: "jaenmarcos15@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/jaen15marcos",
		linkedin: "https://www.linkedin.com/in/jaencort/",
		instagram: "https://www.instagram.com/marcos15jaen/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "KDB+ Programmer & Math Enthusiast",
		description:
			"Hi, I'm a KDB+ programmer based in Toronto. My current role primarily involves in-depth research and analysis of tick-level data from Canadian marketplaces. I mainly work on collaborative research concerning market microstructures and sector trends. With a background in pure mathematics, I am passionate about applying pure maths, statistics, and linear algebra to optimize algorithms in space-time. I also leverage mathematical theories to create trading strategies and to understand the market. I enjoy solving complex problems and learning new skills.",
	},

	about: {
		title: "An Aspiring Quant",
		description:
			"I always try to push myself to be better at what I do. Hence, I continously work in new projects. Please, refer to my resume below, for more information of who am I and what I do.",
		},

	articles: {
		title: "A walkthrough some of my projects.",
		description:
			"Chronological collection of some of my projects mainly focused on 3 areas: risk (e.g., ex-ante risk, composition of portfolio), alpha and execution (e.g., price impact).",
	},

	projects: [
		{
			title: "Trading Strategies in KDB+",
			description:
				"Implemenation of trading strategies, including ETF pair-trading.",
			logo: "https://dbdb.io/media/logos/Kx_Logo_blue_large.png.280x250_q85.png",
			linkText: "View Project",
			link: "https://www.jaenmarcos.com/article/1",
		},

		{
			title: "Predicting Realized Volatility with ML",
			description:
				"Developed and optimized LightGBM, LSTM, and GBM models for predicting stock volatility.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://www.jaenmarcos.com/article/2",
		},

		{
			title: "Q Challenges",
			description:
				"Interesting Q problems to challenge yourself with.",
			logo: "https://dbdb.io/media/logos/Kx_Logo_blue_large.png.280x250_q85.png",
			linkText: "View Project",
			link: "https://github.com/jaen15marcos/q/blob/main/q_challenges.q",
		},

		{
			title: "A Multi-Order Type Book",
			description:
				"Creating a multi-order type orderbook in KDB+ from scratch. (Work in Progress)",
			logo: "https://dbdb.io/media/logos/Kx_Logo_blue_large.png.280x250_q85.png",
			linkText: "View Project",
			link: "https://github.com/jaen15marcos",
		},

		{
			title: "Full-Stack Market Dashboard",
			description:
				"A full-stack trading analytics dashboard using Nextjs 14, React, Shacn UI, Tailwind CSS and Supabase. (Work in Progress)",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/jaen15marcos",
		},
	],
};

export default INFO;
